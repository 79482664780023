import React, { useState } from 'react';
import { loadUsers, exportUserData } from '../../../../actions/userActions';
import { Link } from 'react-router-dom';
import {
  Table,
  SimpleTextFilter,
  getTableSettingsDefaults,
} from '../../../common/Table';
import { CommaNumber_NoDecimal } from '../../../../util/formatter';
import Loading from '../../../common/Loading';
import KwPanel from '../../../common/KwPanel';
import useTableSettings from '../../../../hooks/useTableSettings';
import Gravatar from 'react-gravatar';
import CompanyLink from '../../../common/links/CompanyLink';
import YesNoLabel from '../../../common/YesNoLabel';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import { useTranslation } from 'react-i18next';

function UserList({
  location,
  reload,
  userProfileGroupById,
  companyTypes,
  connectedUsers,
}) {
  const { t } = useTranslation();
  const allColumns = [
    'fullName',
    'requestActivity',
    'keyActivity',
    'companyName',
    'companyTypeName',
    'emailAddress',
    'username',
    'isAuthorised',
    'creditLimit',
    'authenticationMethodName',
    'allowedTerritories',
    'directPermissions',
    'groupMemberships',
  ];
  const defaultVisibleColumns = allColumns.filter(
    (x) =>
      x !== 'keyActivity' &&
      x !== 'creditLimit' &&
      x !== 'authenticationMethodName'
  );
  const [users, setUsers] = useState();

  const params = new URLSearchParams(location.search);
  const { tableSettings, setTableSettings, filters, filterChange, isLoading } =
    useTableSettings(
      userProfileGroupById
        ? 'userlist-group-2.14-settings'
        : 'userlist-2.14-settings',
      getTableSettingsDefaults({
        defaultSort: 'fullName',
        defaultVisibleColumns,
      }),
      { userProfileGroupById },
      loadUsers,
      setUsers,
      reload,
      t('Failed to load users'),
      location
    );

  // The base config of the columns, this will be filtered based on the visible (above)
  let columnsConfig = [
    {
      Key: 'fullName',
      Label: t('Name'),
      width: '20%',
      Render: (item) => (
        <div style={{ display: 'flex' }}>
          <Gravatar
            email={item?.username}
            size={20}
            rating="pg"
            default="retro"
            className="img-circle me-3"
          />
          {!item.fullName && !item.inviteAcceptedDate ? (
            <Link to={'/user/' + item.id}>
              <i>{item.emailAddress}</i>
              <span className="badge bg-info ms-3">{t('Invite pending')}</span>
            </Link>
          ) : (
            <>
              <Link to={'/user/' + item.id}>
                {item.fullName}
                {connectedUsers &&
                  connectedUsers.users &&
                  connectedUsers.users.filter((x) => x && x.id === item.id)
                    .length > 0 && (
                    <span className="badge bg-info ms-3">{t('Active')}</span>
                  )}
              </Link>
            </>
          )}
        </div>
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'requestActivity',
      Label: t('Request activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.requestActivityScore30to60Days,
            item.requestActivityScore7to30Days,
            item.requestActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.requestActivityScore30to60Days +
                item.requestActivityScore7to30Days +
                item.requestActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#46b29d" />
        </Sparklines>
      ),
    },
    {
      Key: 'keyActivity',
      Label: t('Key activity'),
      width: '5%',
      Render: (item) => (
        <Sparklines
          data={[
            item.keyActivityScore30to60Days,
            item.keyActivityScore7to30Days,
            item.keyActivityScoreLast7Days,
          ]}
          min={0}
          max={100}
          height={20}
          width={60}
          style={{
            opacity:
              item.keyActivityScore30to60Days +
                item.keyActivityScore7to30Days +
                item.keyActivityScoreLast7Days >
              0
                ? 1
                : 0.5,
          }}
        >
          <SparklinesCurve color="#bb5ebf" />
        </Sparklines>
      ),
    },
    {
      Key: 'companyName',
      Label: t('Company'),
      width: '10%',
      Render: (item) => (
        <CompanyLink id={item.companyId} name={item.companyName} />
      ),
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'companyTypeName',
      Label: t('Company type'),
      width: '10%',
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('--- All ---')}</option>
            {companyTypes &&
              [...companyTypes]
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.map((x) => (
                  <option key={x.id} value={x.name}>
                    {x.name}
                  </option>
                ))}
          </select>
        ),
      },
    },
    {
      Key: 'emailAddress',
      Label: t('Email'),
      width: '5%',
      Render: (item) => item.emailAddress ?? '---',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'username',
      Label: t('Username'),
      width: '5%',
      Render: (item) => item.username,
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'isAuthorised',
      Label: t('Authorised'),
      width: '10%',
      Render: (item) => <YesNoLabel value={item.isAuthorised} />,
      Filter: {
        Render: (col) => (
          <select
            className={`form-control ${
              params.get(`f_${[col.Key]}`) ? 'filtered' : ''
            }`}
            value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(el) => filterChange(col.Key, el.target.value)}
          >
            <option value="">{t('--- All ---')}</option>
            <option value="true">{t('Yes')}</option>
            <option value="false">{t('No')}</option>
          </select>
        ),
      },
    },
    {
      Key: 'creditLimit',
      Label: t('Credit limit'),
      width: '10%',
      className: 'text-right num no-wrap',
      Render: (item) => CommaNumber_NoDecimal(item.creditLimit),
    },
    {
      Key: 'authenticationMethodName',
      Label: t('Authentication method'),
      width: '10%',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'allowedTerritories',
      Label: t('Allowed territories'),
      width: '5%',
      Render: (item) => item.allowedTerritories ?? 'All allowed',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'directPermissions',
      Label: t('Direct permissions'),
      width: '5%',
      Render: (item) => item.directPermissions ?? 'None',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
    {
      Key: 'groupMemberships',
      Label: t('Group memberships'),
      width: '5%',
      Render: (item) => item.groupMemberships ?? 'None',
      Filter: {
        Render: (col) => (
          <SimpleTextFilter
            Key={col.Key}
            Value={params.get(`f_${[col.Key]}`) || ''}
            onChange={(value) => filterChange(col.Key, value)}
          />
        ),
      },
    },
  ];

  // filter
  const visibleColumns = tableSettings.columns ?? defaultVisibleColumns;
  const columns = visibleColumns
    .map((x) => columnsConfig.find((y) => y.Key === x))
    .filter((x) => x);

  // return an alaska table
  return users ? (
    <KwPanel className="mb-4">
      <Table
        columns={columns}
        allColumns={allColumns}
        defaultVisibleColumns={defaultVisibleColumns}
        rows={users.data.map((x) => {
          return { Key: x.id, ...x };
        })}
        totalRowCount={users.totalRowCount}
        filteredRowCount={users.filteredRowCount}
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        isLoading={isLoading}
        filters={filters}
        filterChange={filterChange}
        exportData={exportUserData}
        scopes={users.scopes}
        location={location}
      />
    </KwPanel>
  ) : (
    <Loading></Loading>
  );
}
export default UserList;
